<!--
  @description: 字典组件
 -->
<template>
  <div>
    <!-- 查看模式 -->
    <template v-if="mode == 'text'">
      <span>{{dictText}}</span>
    </template>

    <!-- 编辑模式 -->
    <template v-else>
      <template v-if="parentComponentType == 'select'">
        <el-option :label="item[labelField]" :value="item[valueField]" v-for="(item, index) in dictList" :key="index"/>
      </template>
      <template v-if="parentComponentType == 'radio'">
        <el-radio :label="item[labelField]" :value="item[valueField]" v-for="(item, index) in dictList" :key="index"/>
      </template>
      <template v-if="parentComponentType == 'checkbox'">
        <el-checkbox :label="item[labelField]" :value="item[valueField]" v-for="(item, index) in dictList" :key="index"/>
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup name="HDict">
  import {defineProps, withDefaults, watch, ref, getCurrentInstance} from "vue";
  import DictCache from "@hview/hview-utils/lib/cache/dict";
  const instance = getCurrentInstance();
  /**
   * 字典list
   */
  const dictList = ref([]);

  /**
   * 显示的文本
   */
  const dictText = ref("");

  /**
   * 父组件类型
   */
  const parentComponentType = ref("");

  /**
   * 模式类型枚举
   */
  enum ModeEnum {
    // 文本,回显模式
    TEXT = "text"
  }

  /**
   * 数据源类型
   */
  enum DataSourceEnum {
    // 默认
    DEFAULT = "default",
    // 自定义
    CUSTOM = "custom"
  }

  /**
   * props
   */
  interface Props {
    /**
     * 字典模式，默认text，可选select、radio、checkbox
     */
    mode?: ModeEnum;
    /**
     * 数据源（默认default, 可选custom）
     */
    dataSource?: DataSourceEnum;
    /**
     * label字段，label
     */
    labelField?: string;
    /**
     * value字段，默认value
     */
    valueField?: string;
    /**
     * 字典key
     */
    dictKey: string;
    /**
     * v-model绑定的值
     */
    modelValue?: string;
    /**
     * 自定义数据源必填项
     */
    list?: any[]
  }

  const props = withDefaults(defineProps<Props>(), {
    mode: "",
    dataSource: "default",
    labelField: "label",
    valueField: "value"
  });

  const init = () => {
    // 非text模式自动查找父组件类型，自动匹配
    if (props.mode !== "text") {
      const parentRef = instance.parent;
      if (parentRef && parentRef.type) {
        const name = parentRef.type.name;
        switch (name) {
          case "ElRadioGroup":
            parentComponentType.value = "radio";
            break;
          case "ElOptions":
            parentComponentType.value = "select";
            break;
          case "ElCheckboxGroup":
            parentComponentType.value = "checkbox";
            break;
        }
      }
    }
    // 自定义list
    if (props.dataSource == DataSourceEnum.CUSTOM) {
      if (!props.list || props.list.length == 0 ) {
        return;
      }
      dictList.value = props.list;
    } else { // 直接读取缓存
      if (!props.dictKey) {
        throw new Error("请填写dict key");
      }
      const dict = DictCache.getDict();
      const dictDataList =dict[props.dictKey];
      if (!dictDataList) {
        throw new Error(`未找到字典项，key：【${props.dictKey}】`);
      }
      dictList.value = dictDataList;
    }
    // 有值代表回显
    if (props.modelValue || props.modelValue == 0) {
      const item = dictList.value.find((item: any) => item[props.valueField] + "" == props.modelValue + "");
      if (item) {
        dictText.value = item[props.labelField];
      } else {
        dictText.value = "--";
      }
    } else {
      dictText.value = "--";
    }
  }

  init();

  watch(() => props.modelValue, () => {
    init();
  });

  watch(() => props.list, () => {
    init();
  });

</script>

<style scoped>

</style>