/**
 * @description 微前端应用
 */
import { localStorage } from "../storage";
import { development } from "@config/application";

/**
 * @description 微应用类型
 */
export interface MicroApp {
    /**
     * 路由
     */
    path: string;
    /**
     * 应用名称 (必传)
     */
    name: string;
    /**
     * 应用地址(必传)
     */
    url: string;
    /**
     * 指定默认渲染的页面
     */
    defaultPage?: string;
    /**
     * 设置子应用的基础路由
     */
    baseRoute?: string;
    /**
     * 描述
     */
    description?: string;
}

export enum MicroMessageType {
    // 路由
    ROUTE = "route"
}
/**
 * @description 微前端消息
 */
export interface MicroMessage {
    type: MicroMessageType;
    data: any;
}



const CACHE_KEYS = {
    MICROAPP: "microapp"
};

/**
 * @description 微应用缓存工具
 */
export default class MicroAppCache {

    /**
     * @description 设置微应用配置
     * @param data 微应用数据
     */
    public static setMicroApp(data: MicroApp[]): void {
        if (!data || data.length == 0) {
            throw new Error("设置微应用数据不能为空!");
        }
        localStorage.set(CACHE_KEYS.MICROAPP, data);
        console.log(data)
        console.log(CACHE_KEYS.MICROAPP)
    }

    /**
     * @description 获取微应用配置
     * @return MicroApp[]
     */
    public static getMicroApp(): MicroApp[] | null {
        // 如果是开发环境，读取本地配置文件，否则读取缓存数据
        if (process.env.NODE_ENV == "development") {
            console.info("当前开发环境，微应用数据正在读取开发配置....");
            console.log(development.microApps)
            return development.microApps;
        }
        const data: MicroApp[] = localStorage.get(CACHE_KEYS.MICROAPP);
        if (data && data.length >= 0) {
            return data;
        }
        return null;
    }

    /**
     * @description 删除微应用配置
     */
    public static removeMicroApp(): void {
        localStorage.remove(CACHE_KEYS.MICROAPP);
    }

}