import {localStorage} from "../storage";
import UUID from "../tools/uuid";

const CACKE_KEYS = {
    SID: "sid"
};

/**
 * @description sid缓存，此方法因为php端是原始项目，不是前后端分离，所以前端生成
 */
export default class SidCache {

    /**
     * @description 设置sid
     * @param sid
     */
    public static setSid (): void {
        if (!this.getSid()) {
            localStorage.set(CACKE_KEYS.SID, UUID.randomUUID());
        }
    }

    /**
     * @description 获取sid
     */
    public static getSid(): string | null {
        return localStorage.get(CACKE_KEYS.SID);
    }

    /**
     * @description 删除sid
     */
    public static removeSid(): void {
        localStorage.remove(CACKE_KEYS.SID);
    }
}