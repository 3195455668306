import {HLayout} from "@hview/hview-vue3";
const home = [{
    path: '',
    component: HLayout,
    children: [{
        path: '',
        meta: {
            title: "登录"
        },
        component: () => import("@/views/home.vue")
    }]

}];
export default home;