import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import microApp from '@micro-zoe/micro-app';
import "@hview/hview-style/css/animate.min.scss";
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css';
import "@hview/hview-style";
import MicroAppCache from "@hview/hview-utils/lib/cache/micro";
import hview from "@hview/hview-vue3";
import {prod} from "@config/application";
MicroAppCache.setMicroApp(prod.microApps);
import menu from "@config/menu";
import MenuCache from "@hview/hview-utils/lib/cache/menu";
import SidCache from "@hview/hview-utils/lib/cache/sid";
import AppBoot from "@/tools/app-boot";
SidCache.setSid();
MenuCache.setMenu(menu);
microApp.start();
AppBoot.start();

const app = createApp(App);
microApp.router.setBaseAppRouter(router);
app.use(hview).use(router).use(ElementPlus).mount('#app');
console.info(`%c欢迎访问千流健康Portal系统`, "color:#00AA96;font-size: 22px;font-weight:bolder");