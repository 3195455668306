import {Menu} from "@hview/hview-vue3";
import {localStorage} from "../storage";

const CACHEKEYS = {
    MENU: "menu"
};
export default class MenuCache {
    /**
     * @description 设置菜单
     * @param menu
     */
    public static setMenu(menu: Menu[]):void {
        localStorage.set(CACHEKEYS.MENU, menu);
    }

    /**
     * @description 获取菜单
     */
    public static getMenu(): Menu[] | null {
        const menu = localStorage.get(CACHEKEYS.MENU);
        if (menu) {
            return menu;
        }
        return null;
    }

    /**
     * @description 删除菜单
     */
    public static removeMenu():void {
        localStorage.remove(CACHEKEYS.MENU);
    }


}