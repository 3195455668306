/**
 * @description: 用户缓存
 * @author zhaxianhe
 * @version v1.0
 */
import {localStorage} from "../storage";
const CACKE_KEYS = {
    TOKEN: "token",
    APPID: "appid",
    USERINFO: "userinfo",
    // 租户信息
    TENANT: "tenant",
    DEFAULTTENANT: "defaulttenant"

};

/**
 * @description 租户信息
 */
export interface Tenant {
    tenantName: string;
    tenantId: number;
}

/**
 * @description 用户信息
 */
export interface UserInfo{
    // 手机号
    mobile: string;
    // 租户名称
    tenantName: string;
    // 用户名
    userName: string;
    // 头像
    avatar: string;
    // 分类id
    category_id: number;
    // 默认team
    default_team_id: number;
    id: number;
    is_ai_open_reply: number;
    is_allow_voice_chat: number;
    nickname: string;
    realname: string;
    user_id: string;
}


export default class UserCache {

    /**
     * @description 设置token
     * @param token 用户token
     */
    public static setToken(token: string): void {
        localStorage.set(CACKE_KEYS.TOKEN, token);
    }

    /**
     * @description 获取token
     * @return string
     */
    public static getToken(): string {
        return localStorage.get(CACKE_KEYS.TOKEN);
    }

    /**
     * @description 删除token
     */
    public static removeToken(): void {
        localStorage.remove(CACKE_KEYS.TOKEN);
    }

    /**
     * @description 设置用户信息
     * @param userInfo
     */
    public static setUserInfo(userInfo: UserInfo) {
        localStorage.set(CACKE_KEYS.USERINFO, userInfo);
    }

    /**
     * @description 获取用户信息
     */
    public static getUserInfo(): UserInfo {
        return localStorage.get(CACKE_KEYS.USERINFO);
    }

    /**
     * @description 删除用户信息
     */
    public static removeUserInfo():void {
        localStorage.remove(CACKE_KEYS.USERINFO);
    }

    /**
     * @description 设置应用id
     * @param id
     */
    public static setAppId(id: string): void {
        localStorage.set(CACKE_KEYS.APPID, id);
    }

    /**
     * @description 获取应用id
     */
    public static getAppId(): string {
        return localStorage.get(CACKE_KEYS.APPID);
    }

    /**
     * @description 删除appid
     */
    public static removeAppId(): void {
        localStorage.remove(CACKE_KEYS.APPID);
    }

    /**
     * @description 设置默认租户
     * @param tenant
     */
    public static setDefaultTenant(tenant: any) {
        localStorage.set(CACKE_KEYS.DEFAULTTENANT, tenant);
    }

    /**
     * @description 获取默认租户
     */
    public static getDefaultTenant(): Tenant | null {
        return localStorage.get(CACKE_KEYS.DEFAULTTENANT) || null;
    }

    /**
     * @description 删除默认租户
     */
    public static removeDefaultTenant(): void {
        localStorage.remove(CACKE_KEYS.DEFAULTTENANT);
    }

    /**
     * @description 设置租户信息
     * @param tenant
     */
    public static setTenant (tenant: any[]): void {
        localStorage.set(CACKE_KEYS.TENANT, tenant);
    }

    /**
     * @description 获取租户信息
     */
    public static getTenant(): any[] {
        return localStorage.get(CACKE_KEYS.TENANT) || [];
    }

    /**
     * @description 获取team
     */
    public static getTeam() {
        return localStorage.get(CACKE_KEYS.DEFAULTTENANT)[1] || null;
    }

    /**
     * @description 删除租户信息
     */
    public static removeTenant(): void {
        localStorage.remove(CACKE_KEYS.TENANT);
    }

    /**
     * @description 清空用户所有缓存
     */
    public static clearAll():void {
        this.removeAppId();
        this.removeToken();
        this.removeUserInfo();
        this.removeTenant();
        this.removeDefaultTenant();
    }
}