<template>
  <div class="h-layout">
    <div class="h-layout-header">
      <div class="h-layout-header-logo">
        <img class="w-50 m-r-10" src="https://aixin-download.oss-cn-beijing.aliyuncs.com/qianliu-admin/qianliu/logo-2.png"/>
      </div>
      <div class="font-size-24">
        千流健康管理
      </div>
      <div>
      </div>
      <div class="h-layout-header-center"></div>
      <div class="h-layout-header-right">
        <div class="message"></div>
        <div class="tenant">
          <el-cascader placeholder="请选择租户和社区" v-model="defaultTenants" @change="changeTenants" :options="tenants" />
<!--          <el-dropdown @command="commandEvent">-->
<!--            <span class="el-dropdown-link">{{defaultTenants.name}}<i class="iconfont icon-jiantou"></i></span>-->
<!--            <template #dropdown>-->
<!--              <el-dropdown-menu>-->
<!--                <el-dropdown-item :class="{'tenant-select' : item.tenant_id == defaultTenants.tenant_id}" v-for="(item, index) in tenants" :key="index" :command="item">-->
<!--                  {{item.name}} <i v-if="item.tenant_id == defaultTenants.tenant_id" class="tenant-checked iconfont icon-xuanzhong"></i>-->
<!--                </el-dropdown-item>-->
<!--              </el-dropdown-menu>-->
<!--            </template>-->
<!--          </el-dropdown>-->
        </div>
        <div class="user">
          <el-dropdown @command="userCommandEvent">
            <div class="flex align-items-center cursor-pointer color-primary" >
              <span class="m-r-6">{{userInfo.realname || userInfo.nickname}}</span>
              <span class="iconfont icon-yonghu"></span>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
<!--                <el-dropdown-item><i class="el-icon-setting"></i>设置</el-dropdown-item>-->
                <el-dropdown-item @click="logout" command="logout" divided><i class="el-icon-switch-button"></i>退出系统</el-dropdown-item>
              </el-dropdown-menu>
            </template>

          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="h-layout-container">
      <div class="h-layout-container-menu">
        <div class="h-layout-container-menu_list">
          <el-scrollbar class="h-layout-container-menu_list__scrollbar">
            <div>
              <div class="menu-item" v-for="(item, index) in menuList" :class="{active: item.id == activeAppId}" @click="menuEvent(item)" :key="index">
                <div class="icon">
                  <span class="iconfont" :class="item.icon"></span>
                </div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div class="h-layout-container-submenu" v-if="currentMenu && currentMenu.children && currentMenu.children.length > 0">
        <h-menu :menuList="currentMenu.children" :active="selectPath"/>
      </div>
      <div class="h-layout-container-router_view" v-if="isRouterAlive">
        <slot v-if="slotDefault"/>
        <router-view v-else/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ElMessageBox, ElMessage } from 'element-plus'
  import {useSlots, ref, onMounted, onUnmounted} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import type {Menu} from "./type";
  import MenuCache from "@hview/hview-utils/lib/cache/menu";
  import microApp from '@micro-zoe/micro-app';
  import HMenu from "./h-menu";
  import MicroAppCache, {MicroMessage, MicroMessageType} from "@hview/hview-utils/lib/cache/micro";
  import UserCache from "@hview/hview-utils/lib/cache/user";
  import {userLogoutApi} from "./api";
  import MicroUtils from "@hview/hview-utils/lib/tools/micro-utils";
  const router = useRouter();
  const route = useRoute();
  const isRouterAlive = ref(true);
  // 菜单列表
  let menuList = ref([]);

  // 从缓存中获取菜单
  menuList.value = MenuCache.getMenu() || [];

  const slotDefault = !!useSlots().default;

  const userInfo = UserCache.getUserInfo();

  const tenants = UserCache.getTenant();
  const defaultTenants = ref([]);
  defaultTenants.value = UserCache.getDefaultTenant();

  const logout = () => {
    ElMessageBox.confirm(
        '确定退出当前系统吗?',
        '提示',
        {
          type: 'warning'
        }
    ).then(() => {
      userLogoutApi().then(() => {
        UserCache.clearAll();
        MicroUtils.toLogin();
      });
    });
  }

  let model = ref({
    tenant: ""
  });


  /**
   * 当前选中的菜单
   */
  let currentMenu = ref<Menu | null>(null);

  /**
   * 选中的路径
   */
  let selectPath = ref<string>("");

  /**
   * 激活的app
   * @private
   */
  let activeAppId = ref<number>(-1);

  /**
   * @description 用户触发下拉
   */
  const userCommandEvent = (data: string) => {
    // console.info(data);
  }

  /**
   * @description 切换租户与社区
   */
  const changeTenants = (data: any) => {
    UserCache.setDefaultTenant(data);
    ElMessage.success("切换成功");
    isRouterAlive.value = false;
    setTimeout(() => {
      isRouterAlive.value = true;
    }, 300);
  }

  /**
   * @description 切换租户
   */
  const commandEvent = (data: any) => {
    if (data.tenant_id == defaultTenants.value.tenant_id) {
      ElMessage.warning(`当前已经是【${data.name}】`);
      return;
    }
    ElMessageBox.confirm(
        `确认切换到【${data.name}】吗?`,
        '提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '暂不切换',
          type: 'warning',
        }
    ).then((res: string) => {
      if (res == "confirm") {
        UserCache.setDefaultTenant(data);
        defaultTenants.value = data;
        if (route.path == "/") {
          // location.reload();
          isRouterAlive.value = false;
          setTimeout(() => {
            isRouterAlive.value = true;
          }, 300);



        } else {
          router.push("/");
        }

      }
    });
  }
  /**
   * @description 获取需要打开的菜单
   */
  const getMenu = (menu: Menu): Menu => {
    // 有子菜单，则直接找子菜单
    if (menu.children && menu.children.length > 0) {
      const menuItem = menu.children[0];
      return getMenu(menuItem);
    } else {
      return menu;
    }
  }

  const menuEvent = (data: Menu) => {
    const menu = getMenu(data);
    // 如果配置了app，代表是加载子系统，否则直接是打开基座系统的页面
    if (menu.app) {
      const microApp = MicroAppCache.getMicroApp() || [];
      // 获取微应用
      const micro = microApp.find((item => item.name == menu.app));
      if (micro) {
        window.history.pushState(null, '', `${micro.baseRoute || micro.path}${menu.path}`);
        // 主动触发一次popstate事件
        window.dispatchEvent(new PopStateEvent('popstate', { state: null }));
        activeAppId.value = data.id;
        currentMenu.value = data;
      } else {
        throw new Error(`未找到微应用配置, appName:【${data.app}】`);
      }
    } else {// 基座系统内跳转
      activeAppId.value = 1;
      router.push(data.path);
    }
  }

  /**
   * @description 微应用全局数据监听
   * @param data
   * @private
   */
  const globalDataListener = (data: MicroMessage) =>{
    // 路由改变触发
    if (data.type == MicroMessageType.ROUTE) {
      microRouteEvent(data.data);
    }
  }

  /**
   * @description 选中父菜单
   * @param data
   * @private
   */
  const selectParentMenu = (matched: any): any => {
    const appName = (route && route.name) || "";
    if (!appName) {
      return false;
    }
    let path = matched.path;
    // 如果子页面指定了选中父菜单，则用指定的菜单进行选中
    if (matched.meta && matched.meta.activeMenu) {
      path = matched.meta.activeMenu;
    }
    if (menuList) {
      const menus = menuList.value.filter(item => item.app == appName);
      const menuApp = getAppMenuByPath(menus, path);
      if (menuApp) {
        currentMenu.value = menuApp;
        activeAppId.value = menuApp.id;
      }
    }
  }

  /**
   * @description 通过path获取菜单
   * @param menuList
   * @param path
   * @private
   */
  const getAppMenuByPath = (menuList: Menu[], path: string): any => {
    for (let i = 0; i < menuList.length; i++) {
      const item = menuList[i];

      if (item.path == path) {
        return item;
      } else if (item.children && item.children.length > 0) {
        const result = getAppMenuByPath(item.children, path);
        if (result) {
          return item;
        }
      }
    }
  }

  /**
   * @description 子应用路由变化触发
   * @param data
   * @private
   */
  const microRouteEvent = (data: any) => {
    // 获取子应用to对象与当前子应用下所有路由表
    const {to, routes} = data;
    if (to && to.meta && to.meta.title) {
      document.title = to.meta.title;
    }
    // 获取matched第一条数据
    const matched = to.matched[0];
    if (!matched) {
      return null;
    }
    // 选中父菜单
    selectParentMenu(matched);
    // 获取当前根path
    const { path } = matched;
    // 获取路由项
    const routeItem = routes.find((item: RouteConfig) => item.path == path);
    const children = routeItem.children || [];
    let selectPathStr = path;
    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      if (item.meta && item.meta.primary) {
        selectPathStr += item.path;
      }
    }
    // 处理选择的path
    selectPathStr = selectPathStr.replace(new RegExp("/$"), "");
    selectPath.value = selectPathStr;
  }

  /**
   * @description 初始化
   * @private
   */
  const init = () => {
    // 没有name的代表是当前项目
    if (route && !route.name && menuList) {
    const {path} = route;
    // 获取portal中的路由
    const portalRoute = menuList.value.filter(item => item.app == "") || [];
    const menu: Menu | null = portalRoute.find(item => item.path == path) || null;
    if (menu) {
      currentMenu.value = menu;
      activeAppId.value = menu.id;
    }
  }

  }

  const authMenu = () => {
    const {isadmin} = UserCache.getUserInfo();
    const menus = [{
      id: 5,
      text: "表单",
      icon: "icon-biaodan",
      path: "/form",
      app: "doctor"
    }, {
      id: 6,
      text: "项目",
      icon: "icon-xiangmu",
      path: "/project",
      app: "doctor"
    }, {
      id: 7,
      text: "中心管理",
      icon: "icon-chengyuan",
      path: "/team",
      app: "doctor"
    }, {
      id: 8,
      text: "医生审核",
      icon: "icon-shenhe",
      path: "/audit-doctor",
      app: "doctor"
    }, {
      id: 9,
      text: "患者审核",
      icon: "icon-shenhe",
      path: "/audit-patient",
      app: "doctor"
    }, {
      id: 10,
      text: "优惠券",
      icon: "icon-shenhe",
      path: "/coupon",
      app: "doctor"
    }];
    menuList.value.push(...menus);
  }

  /**
   * @description mounted
   */
  onMounted(() => {
    microApp.addGlobalDataListener(globalDataListener);
    init();
    authMenu();
  });

  /**
   * @description onUnmounted
   */
  onUnmounted(() => {
    microApp.removeGlobalDataListener(globalDataListener)
  })
</script>

<style lang="scss">
.tenant-select {
  color: var(--primary-color) !important;
  .tenant-checked {
    margin-left: 8px;
  }
}

  .h-layout {
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    background: var(--background-color);
    /* 顶部导航部分 */
    &-header {
      height: 64px;
      background: #FFFFFF;
      box-shadow: inset -1px 0 0 1px #e5ecf0;
      display: flex;
      align-items: center;
      padding: 10px 20px 10px 10px;
      box-sizing: border-box;
      justify-content: space-between;
      &-logo {
        display: flex;
        align-items: center;
      }
      &-center {
        flex: 1;
      }
      &-right {
        display: flex;
        align-items: center;
        .tenant {
          margin: 0 30px 0px 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .user {
          .icon-yonghu {
            font-size: 25px;
          }
        }
      }
    }
    /* 底部容器部分 */
    &-container {
      height: calc(100vh - 64px);
      display: flex;
      flex: 1;
      /* 左侧主菜单部分 */
      &-menu {
        flex: 0 0 64px;
        background: #FFFFFF;
        box-shadow: inset -1px 0 0 0 #e5ecf0;
        flex-direction: column;
        height: 100%;
        display: flex;
        overflow: scroll;
        /* 主菜单列表 */
        &_list {
          /* 主菜单滚动条 */
          &__scrollbar {
            height: calc(100vh - 64px);
          }/* 主菜单项 */
          .menu-item {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 72px;
            justify-content: center;
            margin-bottom: 4px;
            position: relative;
            text-align: center;
            text-decoration: none;
            transition: all .3s;
            color: var(--font-secondary-color);
            .icon {
              span {
                font-size: 24px;
              }
            }
            .text {
              font-size: 13px;
              line-height: 18px;
              margin-top: 4px;
            }
            &.active {
              background: var(--primary-color);
              color: #fff;
            }
          }
        }
      }
      /* 子菜单部分 */
      &-submenu {
        display: flex;
      }
      /* 右侧内容区域 */
      &-router_view {
        flex: 1;
        padding: 15px 0px 0px 15px;
        box-sizing: border-box;
      }
    }

  }
</style>