/**
 * @description 应用启动执行
 */
import microApp from '@micro-zoe/micro-app'
import Socket from "@hview/hview-lib/lib/socket";
import {mitter} from "@hview/hview-vue3/packages";


export default class AppBoot {

    public static start() {
        let socket = (window as any).$scoket;
        if (!socket) {
            socket = new Socket();
            socket.connect();
            // 挂载到window下
            (window as any).$scoket = socket;
            // 监听私聊消息
            mitter.on("on-chat-msg", (data: any) => {
                microApp.setGlobalData({type: "msg",
                    data
                });
            });
        } else {
            socket.connect();
        }
    }
}