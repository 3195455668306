<!--
  @description: 文件上传
-->
<template>
  <el-upload
      :on-success="handleAvatarSuccess"
      :headers="headers"
      :action="action"
      :show-file-list="false">
    <slot :file="uploadData" v-if="imageUrl" name="file"/>
    <slot v-else name="default"/>
  </el-upload>
</template>

<script lang="ts" setup name="HUpload">
  import {ref, defineEmits, defineProps} from "vue";
  import {getApiPath} from "@config/application";
  import UserCache from "@hview/hview-utils/lib/cache/user";
  defineProps<{
    modelValue: string
  }>();
  const emits = defineEmits(["update:modelValue"]);

  const imageUrl = ref("");
  const action = `${getApiPath()}/api/common/upload`;

  const uploadData = ref({});

  const headers = ref({
    token: UserCache.getToken(),
    uid: UserCache.getUserInfo().id || 0
  });

  const handleAvatarSuccess: UploadProps['onSuccess'] = (
      response,
      uploadFile
  ) => {
    uploadData.value = response.data;
    emits("update:modelValue", response.data.url);
    imageUrl.value = URL.createObjectURL(uploadFile.raw!)
  }
</script>

<style lang="scss">

</style>