<!--
  @description: 子菜单项
  @author zhaxianhe
  @version v1.0
-->
<template>
  <div class="h-menu-item">
    <template v-if="menu.children && menu.children.length > 0">
      <div class="menu-group">
        <div class="group-title flex align-items__center">
          <span v-if="menu.icon" class="iconfont m-r__4" :class="menu.icon"></span>
          <span :class="!menu.icon ? 'm-l-20' : ''">{{menu.text}}</span>
        </div>
        <div>
          <template v-for="(item, index) in menu.children" :key="index">
            <h-menu-item :active="active" :menu="item"/>
          </template>

        </div>
      </div>
    </template>
    <template v-else>
      <div class="title flex align-items__center" :class="{'active': menu.path == active}" @click="subMenuEvent(menu)">
        <span v-if="menu.icon" class="iconfont m-r-6" :class="menu.icon"></span>
        <span :class="!menu.icon ? 'm-l-20' : ''">{{menu.text}}</span>
      </div>
    </template>

  </div>
</template>

<script lang="ts" setup>
  import {defineProps, ref} from "vue";
  import {Menu} from "./type";
  const props = defineProps<{
    menu: Menu,
    active: string
  }>();

  const subMenuEvent = (data: Menu) => {
    window.history.pushState(null, '', `/${data.app}${data.path}`);
    // 主动触发一次popstate事件
    window.dispatchEvent(new PopStateEvent('popstate', { state: null }));
  }
</script>

<style lang="scss">
.h-menu-item {
  .menu-group {
    .title {
      padding: 6px 10px 6px 14px;
      font-weight: 400;
    }
  }
  .group-title {
    font-size: 14px;
    padding: 6px 10px 6px 12px;
    font-weight: bold;
    color: var(--font-secondary-color);
  }
  .title {
    padding: 6px 10px 6px 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    //border-radius: 4px;
    color: var(--font-secondary-color);
    transition: all .3s;
    &.active {
      position: relative;
      color: var(--primary-color);
      background: rgba(var(--primary-color-rgb), 0.1);
      &:after {
        position: absolute;
        content: "";
        width: 4px;
        height: 100%;
        top: 0;
        border-radius: 4px;
        right: 0;
        background: var(--primary-color);
      }
      &:hover {
        background: rgba(var(--primary-color-rgb), 0.1);
      }
    }
    &:hover {
      background: var(--background-color);
    }
  }
}
</style>