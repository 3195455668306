/**
 * @description 消息通知组件
 */
import UserCache from "@hview/hview-utils/lib/cache/user";
import { ElNotification } from "element-plus";
export function useMessageNotice () {
     /**
     * @description 判断是否是接收到到消息
     * @param message
     * @return {boolean}
     */
     const isReceive = (data: any) => {
        return data.from_user_id != UserCache.getUserInfo().id;
    }
    /**
     * @description 新消息通知
     * @param data
     */
    const notify = (data: any) => {
        if (UserCache.getUserInfo().id == data.from_user_id) {
            return;
        }

        let message: string = "";
        // 文本消息
        if (data.type == "text") {
            message = `<div class="w-300 text-ellipsis">${data.content}</div>`;
        } else if (data.type == "image") {
            message = "收到一条图片消息";
        } else if (data.type == "card") {
            message = "收到一条卡片消息";
        } else if (data.type == "audio") {
            message = "收到一条语音消息";
        }
        if (message) {
            ElNotification({
                title: data.from_nickname,
                dangerouslyUseHTMLString: true,
                message: message
            });
        }
    }


    return {
        notify
    };
}