import {Menu} from "@hview/hview-vue3";

const menu: Menu[] = [{
    id: 1,
    text: "工作台",
    icon: "icon-gongzuotai",
    path: "/",
    app: ""
}, {
    id: 2,
    text: "消息",
    icon: "icon-xiaoxi",
    path: "/chat",
    app: "doctor"
}, {
    id: 3,
    text: "患者",
    icon: "icon-huanzheguanli2",
    path: "/patient",
    app: "doctor"
}, {
    id: 4,
    text: "文章",
    icon: "icon-wodewenzhang",
    path: "/article",
    app: "doctor"
}];
export default menu;