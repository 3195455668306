import {RouteMeta, Router} from "vue-router";
import UserCache from "@hview/hview-utils/lib/cache/user";
import {ref} from "vue";

/**
 * @description app应用hooks
 */
export function useApp() {
    /**
     * 状态
     */
    const loading = ref<boolean>(false);

    /**
     * 是否显示
     */
    const visible = ref<boolean>(false);

    /**
     * @description 设置浏览器标题
     * @param title
     */
    const setTitle = (meta: RouteMeta) => {
        if (meta) {
            const title: string = meta.title as string;
            document.title = title;
        }
    }

    /**
     * @description 是否需要登录
     * @param toPath
     * @param loginPath
     */
    const needLogin = (toPath: string, loginPath: string) => {
        const token: string | null = UserCache.getToken();
        if (!token && toPath != loginPath) {
          return true;
        }
        return false;
    }

    /**
     * @description 用于解决主应用和子应用都是vue-router4时相互冲突，导致点击浏览器返回按钮，路由错误的问题
     * @param router
     */
    const fixBugForVueRouter4 = (router: Router) => {

        // 判断主应用是main-vue3或main-vite，因为这这两个主应用是 vue-router4
        if ((window as any).__MICRO_APP_ENVIRONMENT__) {
            //if (window.location.href.includes('/main-vue3') || window.location.href.includes('/main-vite')) {
            /**
             * 重要说明：
             * 1、这里主应用下发的基础路由为：`/main-xxx/app-vue3`，其中 `/main-xxx` 是主应用的基础路由，需要去掉，我们只取`/app-vue3`，不同项目根据实际情况调整
             *
             * 2、realBaseRoute 的值为 `/app-vue3`
             */
            const realBaseRoute = (window as any).__MICRO_APP_BASE_ROUTE__;//.replace(/^\/app-tenant-[^/]+/g, '')

            router.beforeEach(() => {
                if (typeof window.history.state?.current === 'string') {
                    window.history.state.current = window.history.state.current.replace(new RegExp(realBaseRoute, 'g'), '')
                }
            });

            router.afterEach(() => {
                if (typeof window.history.state === 'object') {
                    window.history.state.current = realBaseRoute + (window.history.state.current || '')
                }
            });
        }
    }

    return {
        setTitle,
        needLogin,
        fixBugForVueRouter4,
        loading,
        visible
    };
}