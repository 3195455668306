<!--
  @description: 子菜单
  @author zhaxianhe
  @version v1.0
-->
<template>
  <div class="sub-menu" :class="{'retract': isFold}">
    <template v-for="(item, index) in menuList" :key="item.id">
      <h-menu-item v-if="showMenu" :menu="item" :active="active"/>
    </template>
    <div class="sub-menu-arrow">
      <div class="fold" @click="foldEvent">
        <i class="icon iconfont icon-icon_jiantou" :class="{'rotate': !showMenu}"></i>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import {ref} from "vue";
  import HMenuItem from "./h-menu-item.vue";
  import {defineProps} from "vue";
  import {Menu} from "./type";

  const props = defineProps<{
    menuList: Menu[],
    active: string
  }>();

  let isFold = ref<boolean>(false);
  let showMenu = ref<boolean>(true);

  const foldEvent = () => {
    isFold.value = !isFold.value;
    if (isFold.value) {
      showMenu.value = false;
    } else {
      setTimeout(() => {
        showMenu.value = true;
      }, 180);

    }
  }
</script>

<style lang="scss">
.sub-menu {
  width: 114px;
  background: #FFFFFF;
  position: relative;
  transition: all .3s;
  box-shadow: 3px 3px 14px 1px rgba(193,202,208,0.27);
  &.retract {
    width: 0px;

  }
  &-arrow {
    display: flex;
    position: absolute;
    align-items: center;
    height: 100%;
    width: 12px;
    right: -12px;
    top: 0;
    .fold {
      width: 12px;
      height: 60px;
      background: #FFFFFF;
      border-radius: 0 30px 30px 0px;
      box-shadow: 3px 0 3px 0px rgba(193,202,208,0.19);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        font-size: 12px;
        transition: all .3s;
        &.rotate {
          transform: rotate(180deg);
        }
      }
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
</style>