// @ts-ignore
import { ElNotification, ElMessage } from 'element-plus';

/**
 * @description 消息提示选项
 */
export interface NoticeOptions {
  // 主题样式，如果不在可选值内将被忽略
  type?: string;
  // 说明文字
  message: string;
  // 偏移的距离，在同一时刻，所有的 Notification 实例应当具有一个相同的偏移量
  offset?: string;
  // 是否将 message 属性作为 HTML 片段处理
  dangerouslyUseHTMLString?: boolean;
  // 点击 Notification 时的回调函数
  onClick?: Function;
  // 关闭时的回调函数
  onClose?: Function;
  // 是否显示关闭按钮
  showClose?: boolean;
  // 自定义弹出位置
  position?: string;
  // 显示时间, 毫秒。设为 0 则不会自动关闭
  duration?: number;
  // 自定义类名
  customClass?: string;
  // 自定义图标的类名。若设置了 type，则 iconClass 会被覆盖
  iconClass?: string;
}

/**
 * @description 通知提示
 */
export class Notice {

  /**
   * @description 成功提示
   * @param message
   */
  public static success(message: string) {
    if (message) {
      ElNotification({
        type: "success",
        title: "系统提示",
        message: message
      });
    }
  }

  /**
   * @description 警告提示
   * @param message
   */
  public static warning(message: string) {
    if (message) {
      ElNotification({
        type: "warning",
        title: "系统提示",
        message: message
      });
    }
  }

  /**
   * @description 消息提示
   * @param message
   */
  public static info(message: string) {
    if (message) {
      ElNotification({
        type: "info",
        title: "系统提示",
        message: message
      });
    }
  }

  /**
   * @description 错误提示
   * @param message
   */
  public static error(message: string) {
    if (message) {
      ElNotification({
        type: "error",
        title: "系统提示",
        message: message
      });
    }
  }
}

/**
 * @description 消息提示
 */
export class Message {
  /**
   * @description 成功提示
   * @param message
   */
  public static success(message: string) {
    if (message) {
      ElMessage({
        type: "success",
        message: message
      });
    }
  }

  /**
   * @description 警告提示
   * @param message
   */
  public static warning(message: string) {
    if (message) {
      ElMessage({
        type: "warning",
        message: message
      });
    }
  }

  /**
   * @description 消息提示
   * @param message
   */
  public static info(message: string) {
    if (message) {
      ElMessage({
        type: "info",
        message: message
      });
    }
  }

  /**
   * @description 错误提示
   * @param message
   */
  public static error(message: string) {
    if (message) {
      ElMessage({
        type: "error",
        message: message
      });
    }
  }
}