
import {AxiosInstance, AxiosRequestConfig} from "axios";
import HttpInstance from "./src/http-instance";
import {RequestParams} from "./src/types";

export * from "./src/types";
export default class Http {
    // 创建实例
    private static instance: AxiosInstance = new HttpInstance().getInstance();

    /**
     * @description get 请求
     * @param url: string 请求路径
     * @param params: RequestParams 请求参数
     */
    public static get<T = any> (url: string, params?: RequestParams): Promise<T> {
        return this.instance.get(url, params);
    }

    /**
     * @description post 请求
     * @param url: string 请求路径
     * @param data: RequestParams 请求参数
     * @param config: AxiosRequestConfig 请求配置
     */
    public static post<T = any> (url: string, data?: RequestParams, config?: AxiosRequestConfig): Promise<T> {
        return this.instance.post(url, data, config);
    }

    /**
     * @description put 请求
     * @param url: string 请求路径
     * @param data: RequestParams 请求参数
     * @param config: AxiosRequestConfig 请求配置
     */
    public static put<T = any> (url: string, data?: RequestParams, config?: AxiosRequestConfig): Promise<T> {
        return this.instance.put(url, data, config);
    }

    /**
     * @description delete请求
     * @param url: string 请求路径
     * @param params: RequestParams 请求参数
     */
    public static delete<T = any> (url: string, params?: RequestParams): Promise<T> {
        return this.instance.delete(url, params);
    }
}